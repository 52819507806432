
import axios from 'axios';
export default {
    
    async createExtraDays(params)  {
        return await axios.post(`extra_days/create` , params)
    },
    async createExtraDaysList(params)  {
        return await axios.post(`extra_days/create/list` , params)
    },
    async updateExtraDaysColumn(column , value , data)  {
        return await axios.put(`extra_days/update_list?${column}=${value}` , data)
    },
    async deleteExtraDaysList(list)  {
        return await axios.delete(`extra_days/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportExtraDays(column , value)  {
        return await axios.get(`extra_days/report?${column}=${value}`)
    },
    async getAllExtraDays()  {
        return await axios.get(`extra_days/all`)
    },
    async getOneExtraDays(extra_day_id)  {
        return await axios.get(`extra_days/all/${extra_day_id}`)
    },
    async getExtraDaysByColumn(column , value)  {
        return await axios.get(`extra_days/filter?column=${column}&value=${value}`)
    },
    async deleteExtraDays(extra_day_id)  {
        return await axios.delete(`extra_days/delete/${extra_day_id}`)
    },
    async updateExtraDays(extra_day_id , params)  {
        return await axios.put(`extra_days/update/${extra_day_id}` , params)
    }
}