
<template>
	<div>
		<v-container class="my-2">
			<v-layout row wrap px-0 mb-4>
				<v-flex xs12>
					<v-card class="mx-1 px-0" style="background-color:transparent!important" flat>
						<v-card-title class="px-0">
							<!-- <v-breadcrumbs class="px-0" :items="$store.getters.language.data.extra_days.list_path">
								<template v-slot:item="{ item }">
									<v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
										{{ item.title.toUpperCase() }}
									</v-breadcrumbs-item>
								</template>
							</v-breadcrumbs> -->
							<v-spacer></v-spacer>
							<!-- <v-btn class="float-right" color="primary" icon  @click="exportExcel">
                                        <v-icon>mdi-file-excel</v-icon>
                                    </v-btn> -->

						</v-card-title>
					</v-card>
				</v-flex>
			</v-layout>
			<form @submit.prevent="addExtraDays" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-autocomplete class="mx-1" clearable :items="employees" v-model="extra_days.employee_id" dense filled outlined item-text="employee_name" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_name">
						</v-autocomplete>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="extra_days.extra_day_amount" type="number" :label="$store.getters.language.data.extra_days.extra_day_amount" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="extra_days.extra_day_date" type="date" :label="$store.getters.language.data.extra_days.extra_day_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg6 xl6 md6 sm12>
						<v-textarea v-model="extra_days.extra_day_note" type="textarea" :label="$store.getters.language.data.extra_days.extra_day_note" dense class="mx-1" filled outlined>
						</v-textarea>
					</v-flex>
					<!-- <v-flex xs12 lg4 xl4 md6 sm6>
                        <v-text-field v-model="extra_days.extra_day_insert_date" type="datetime-local" :label="$store.getters.language.data.extra_days.extra_day_insert_date" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
					<v-flex xs12 lg3 xl3 md6 sm6>
						<v-select class="mx-1" clearable :items="users" v-model="extra_days.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.extra_days.add_btn}}
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field>
					<v-card>
						<v-card-text>
							<v-data-table :loading="loading" :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="extra_day_id">
								<template v-slot:[`item.extra_day_date`]="{ item }">
									{{  new Date(item.extra_day_date).toLocaleDateString('en-GB') }}
								</template>
								<template v-slot:[`item.extra_day_insert_date`]="{ item }">
									{{  new Date(item.extra_day_insert_date).toLocaleDateString('en-GB') }}
								</template>
                                <template v-slot:[`item.extra_day_amount`]="{ item }">
                                    {{ item.extra_day_amount }} Days
                                </template>
								<template v-slot:[`item.extra_day_id`]="{ item }">
									<div>
										<v-btn icon :to="'/extra_days-list/'+item.extra_day_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on">
													<v-icon> mdi-delete-outline </v-icon>
												</v-btn>
											</template>
											<v-card>
												<v-card-text>
													{{$store.getters.language.data.extra_days.delete_question}}
												</v-card-text>
												<v-card-actions>
													<v-spacer></v-spacer>
													<v-btn text class="elevation-0">
														{{$store.getters.language.data.extra_days.cancel_btn}}
													</v-btn>
													<v-btn color="error" class="elevation-0" @click="deleteExtraDays(item.extra_day_id)">
														{{$store.getters.language.data.extra_days.yes_btn}}
													</v-btn>
												</v-card-actions>
											</v-card>
										</v-menu>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteExtraDaysList">{{$store.getters.language.data.extra_days.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>

		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/extra_days.request.js'
	import * as xlsx from 'xlsx/xlsx.js';

	export default {

		data() {
			return {
				extra_days: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				employees: [], users: [],
				selected_extra_days: {},
				headers: [

					{
						text: this.$store.getters.language.data.employees.employee_name,
						align: 'start',
						sortable: true,
						value: 'employee_name',
					},
					{
						text: this.$store.getters.language.data.extra_days.extra_day_date,
						align: 'start',
						sortable: true,
						value: 'extra_day_date',
					},
					{
						text: this.$store.getters.language.data.extra_days.extra_day_amount,
						align: 'start',
						sortable: true,
						value: 'extra_day_amount',
					},

					{
						text: this.$store.getters.language.data.extra_days.extra_day_note,
						align: 'start',
						sortable: true,
						value: 'extra_day_note',
					},
					{
						text: this.$store.getters.language.data.extra_days.extra_day_insert_date,
						align: 'start',
						sortable: true,
						value: 'extra_day_insert_date',
					},
					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'extra_day_id',
					}
				],
			}
		},
		computed: {
			page() {
				const pageKey = 'EXTRADAYS'
				return {}
			}

		},
		mounted() {
			this.extra_days.user_id = this.$store.getters.user.user_id
			this.readExtraDays();
		},
		methods: {
			// exportExcel(){
			//     const list = this.rows
			//     let result = []
			//     for (let i = 0; i < list.length; i++) {
			//         const item = list[i];
			//         let obj = {extra_day_id : item.extra_day_id,extra_day_date : item.extra_day_date,extra_day_amount : item.extra_day_amount,employee_id : item.employee_id,extra_day_note : item.extra_day_note,extra_day_insert_date : item.extra_day_insert_date,user_id : item.user_id,
			//         }
			//         result.push(obj)
			//     }
			// 	var f = xlsx.utils.json_to_sheet(result)
			// 	var wb = xlsx.utils.book_new()
			// 	xlsx.utils.book_append_sheet(wb, f, 'sheet')
			// 	xlsx.writeFile(wb, result.length+' rows of '+'ExtraDays.xlsx')
			// },
			addExtraDays() {
				this.loading_btn = true
				requests.createExtraDays(this.extra_days).then(r => {
					if (r.status == 200) {
						this.extra_days = {
							user_id: this.$store.getters.user.user_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'ExtraDays Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add ExtraDays',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteExtraDays(extra_day_id) {
				requests.deleteExtraDays(extra_day_id).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.extra_day_id != extra_day_id
						})
						this.snackbar = {
							value: true,
							text: 'ExtraDays Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteExtraDaysList() {
				let ids = this.selected_rows.map(m => m.extra_day_id)
				requests.deleteExtraDaysList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.extra_day_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' ExtraDays Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readExtraDays() {
				this.loading = true
				requests.getAllExtraDays().then(r => {
					if (r.status == 200) {
						this.rows = r.data.extra_days

						this.employees = r.data.employees

						this.users = r.data.users

						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ExtraDays',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ExtraDays',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectExtraDays(i) {
				this.selected_extra_days = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    